import Vue from 'vue'
import VueRouter from 'vue-router'
import { systemRoutes } from 'core/router/index'
import SearchFlight from '../views/SearchFlight.vue'
import WidgetSearchFlight from '../views/WidgetSearchFlight.vue'
import WidgetBooking from '../views/WidgetBooking.vue'
import Booking from '../views/Booking.vue'
import BookingExternal from '../views/BookingExternal'
import store from '../store/index'
import axios from 'axios'

Vue.use(VueRouter)

const routes = systemRoutes.concat([
  {
    path: '/searchflight/',
    name: 'search-flight',
    component: SearchFlight,
    meta: { gtm: 'searchflight' }
  },
  {
    path: '/searchflight/widget/',
    name: 'widget-search-flight',
    component: WidgetSearchFlight,
    meta: { gtm: 'searchflight' }
  },
  {
    path: '/booking/',
    name: 'booking',
    component: Booking,
    meta: { gtm: 'booking' },
    beforeEnter: (to, from, next) => {
      store.commit('setShowHeader', true)
      next()
    }
  },
  {
    path: '/booking/b2b-login/',
    name: 'b2b-login',
    component: Booking
  },
  {
    path: '/booking/widget/',
    name: 'widget-booking',
    props: true,
    component: WidgetBooking,
    meta: { gtm: 'booking' },
    beforeEnter: (to, from, next) => {
      document.body.classList.add('--is-widget')
      store.commit('setShowHeader', false)
      next()
    }
  },
  {
    path: '/booking/external/',
    name: 'booking-external',
    component: BookingExternal,
    meta: { gtm: 'booking' }
  }
])

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_MODULE_PREFIX,
  routes
})

router.afterEach((to, from) => {
  try {
    const versionFile = '/version.txt?t=' + new Date().getTime().toString()
    axios.get(versionFile).then((response) => {
      const latestVersion = String(response.data)
      const appName = (process.env.VUE_APP_MODULE_PREFIX.replaceAll('/', '') !== '' ? process.env.VUE_APP_MODULE_PREFIX.replaceAll('/', '') : 'search')
      // Key para cada SPA
      const keyName = `KIU_APP_VERSION_${appName}`.toLowerCase()
      const clientStoredVersion = (localStorage.getItem(keyName) ?? 0)
      console.log('App:', keyName, '/ Active Version:', clientStoredVersion, '/ Last Version:', latestVersion)

      if (clientStoredVersion !== latestVersion) {
        window.location.reload(true)
        localStorage.setItem(keyName, latestVersion)
      }
    })
  } catch (error) { console.log(error) }
})

export default router
