import Vue from 'vue'
import Vuex from 'vuex'
import { systemStore } from 'core/store/index'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    moduleWidget: null,
    noTabWidget: false,
    showBackButton: false,
    elementsWithOverflow: 0,
    searchFlightConfig: null
  },
  getters: {
    getModuleWidget: state => {
      return state.moduleWidget
    },
    getNoTabWidget: state => {
      return state.noTabWidget
    },
    getSearchFlightConfig: state => {
      return state.searchFlightConfig
    }
  },
  mutations: {
    setModuleWidget (state, module) {
      state.moduleWidget = module
    },
    setNoTabWidget (state, value) {
      state.noTabWidget = value
    },
    setshowBackButton (state, config) {
      state.showBackButton = config
    },
    setSearchFlightConfig (state, config) {
      state.searchFlightConfig = config
    }
  },
  actions: {
    setInitWidget ({ commit }) {
      commit('setModuleWidget', null)
      commit('setNoTabWidget', false)
    },
    notifyOverflowToParent ({ state }, isOverflow) {
      state.elementsWithOverflow += isOverflow ? 1 : -1
      window.parent.postMessage({ kiuElementsOverflow: state.elementsWithOverflow }, '*')
    }
  },
  modules: {
    systemStore
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })]
})
